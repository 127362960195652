import enLogo from "../assets/header/language/en.svg";
import frLogo from "../assets/header/language/fr.svg";
import czLogo from "../assets/header/language/cz.svg";
import deLogo from "../assets/header/language/de.svg";
import esLogo from "../assets/header/language/es.svg";
import grLogo from "../assets/header/language/gr.svg";
import huLogo from "../assets/header/language/hu.svg";
import krLogo from "../assets/header/language/kr.svg";
import itLogo from "../assets/header/language/it.svg";
import nlLogo from "../assets/header/language/nl.svg";
import noLogo from "../assets/header/language/no.svg";
import fiLogo from "../assets/header/language/fi.svg";
import plLogo from "../assets/header/language/pl.svg";
import ptLogo from "../assets/header/language/pt.svg";
import roLogo from "../assets/header/language/ro.svg";
import slLogo from "../assets/header/language/sl.svg";
import svLogo from "../assets/header/language/sv.svg";
import bgLogo from "../assets/header/language/bg.svg";
import hrvLogo from "../assets/header/language/hrv.svg";
import ptbrLogo from "../assets/header/language/pt-br.svg";
import gaLogo from "../assets/header/language/ga.svg";
import iceLogo from "../assets/header/language/ice.svg";
import etLogo from "../assets/header/language/et.svg";
import bnLogo from "../assets/header/language/bn.svg";
import ukLogo from "../assets/header/language/uk.svg";
import trLogo from "../assets/header/language/tr.svg";
import hebLogo from "../assets/header/language/heb.svg";
import zhhLogo from "../assets/header/language/zhh.svg";
import jaLogo from "../assets/header/language/ja.svg";
import acLogo from "../assets/header/language/ar-ae.svg";
// //////////////
import hiLogo from "../assets/header/language/hi.svg";
import msLogo from "../assets/header/language/ms.svg";
import idLogo from "../assets/header/language/id.svg";
import zhLogo from "../assets/header/language/zh.svg";
import srLogo from "../assets/header/language/sr.svg";
import skLogo from "../assets/header/language/sk.svg";
import thLogo from "../assets/header/language/th.svg";
import daLogo from "../assets/header/language/da.svg";
import checkIcon from "../assets/home/check_icon.svg";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns";
import { Cookies } from "react-cookie";
import {
  AsYouType,
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from "libphonenumber-js";
import Moment from "moment-timezone";
import { new_relic_license_key } from "../environment";
import axios from "axios";
import UAParser from "ua-parser-js";

export const validEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,}$/;

export const currency_symbol = [
  "د.إ",
  "؋",
  "L",
  "֏",
  "ƒ",
  "Kz",
  "$",
  "₼",
  "KM",
  "৳",
  "лв",
  "B$",
  ".د.ب",
  ".د.م",
  "N$",
  "FBu",
  "$b",
  "BOV",
  "R$",
  "Re",
  "₿",
  "Nu.",
  "P",
  "Br",
  "BZ$",
  "FJ$",
  "FC",
  "CHE",
  "CHf",
  "CHW",
  "CLF",
  "¥",
  "COU",
  "₡",
  "₱",
  "Kč",
  "Bds$",
  "Fdj",
  "kr",
  "RD$",
  "دج",
  "£",
  "Nfk",
  "Ξ",
  "€",
  "₾",
  "₵",
  "GH₵",
  "D",
  "FG",
  "Q",
  "ر.ق",
  "lei",
  "kn",
  "G",
  "Ft",
  "Rp",
  "₪",
  "₹",
  "ع.د",
  "﷼",
  "SR",
  "J$",
  "JD",
  "KSh",
  "៛",
  "CF",
  "₩",
  "KD",
  "₸",
  "EC$",
  "₭",
  "₨",
  "M",
  "Ł",
  "Lt",
  "Ls",
  "LD",
  "MAD",
  "Ar",
  "K",
  "ر.س",
  "ден",
  "₮",
  "MOP$",
  "UM",
  "Rf",
  "MK",
  "MXV",
  "RM",
  "MT",
  "₦",
  "C$",
  "B/.",
  "S/",
  "zł",
  "Gs",
  "￥",
  "Дин.",
  "₽",
  "R₣",
  "ج.س.",
  "S$",
  "Le",
  "S",
  "Db",
  "E",
  "฿",
  "SM",
  "T",
  "د.ت",
  "T$",
  "₤",
  "₺",
  "TT$",
  "NT$",
  "TSh",
  "₴",
  "USh",
  "UYI",
  "$U",
  "UYW",
  "Bs",
  "Bs.S",
  "₫",
  "VT",
  "WS$",
  "FCFA",
  "Ƀ",
  "CFA",
  "₣",
  "Sucre",
  "XUA",
  "R",
  "ZK",
  "Z$"
];
export const langArray = [
  {
    key: "cs",
    image: czLogo
  },
  {
    key: "en",
    image: enLogo
  },
  {
    key: "fr",
    image: frLogo
  },
  {
    key: "uk",
    image: ukLogo
  },
  {
    key: "de",
    image: deLogo
  },
  {
    key: "hr",
    image: hrvLogo
  },
  {
    key: "es",
    image: esLogo
  },
  {
    key: "it",
    image: itLogo
  },
  {
    key: "is",
    image: iceLogo
  },
  {
    key: "pt",
    image: ptLogo
  },
  {
    key: "ga",
    image: gaLogo
  },
  {
    key: "bn",
    image: bnLogo
  },
  {
    key: "pt-br",
    image: ptbrLogo
  },
  {
    key: "tr",
    image: trLogo
  },
  {
    key: "el",
    image: grLogo
  },
  {
    key: "et",
    image: etLogo
  },
  {
    key: "ja",
    image: jaLogo
  },
  {
    key: "hu",
    image: huLogo
  },
  {
    key: "nl",
    image: nlLogo
  },
  {
    key: "sv",
    image: svLogo
  },
  {
    key: "fi",
    image: fiLogo
  },
  {
    key: "no",
    image: noLogo
  },
  {
    key: "pl",
    image: plLogo
  },
  {
    key: "ro",
    image: roLogo
  },
  {
    key: "sl",
    image: slLogo
  },
  {
    key: "bg",
    image: bgLogo
  },
  {
    key: "he",
    image: hebLogo
  },
  {
    key: "ar",
    image: acLogo
  },
  {
    key: "yue",
    image: zhhLogo
  },
  {
    key: "ko",
    image: krLogo
  },
  {
    key: "hi",
    image: hiLogo
  },
  {
    key: "id",
    image: idLogo
  },
  {
    key: "ms",
    image: msLogo
  },
  {
    key: "sr",
    image: srLogo
  },
  {
    key: "sk",
    image: skLogo
  },
  {
    key: "th",
    image: thLogo
  },
  {
    key: "da",
    image: daLogo
  },
  {
    key: "zh",
    image: zhLogo
  },
  {
    key: "zh-CN",
    image: zhhLogo
  }
];
export const languageLogo = langArray;

export const currency_data = {
  AED: "aed",
  ALL: "all",
  AMD: "amd",
  AOA: "aoa",
  ARS: "ars",
  AUD: "aud2",
  AZN: "azn",
  BAM: "bam",
  BBD: "bbd",
  BDT: "bdt",
  BGN: "bgn",
  BND: "bnd",
  BOB: "bob",
  BRL: "brl",
  BSD: "bsd",
  BWP: "bwp",
  BYN: "byn",
  CAD: "cad2",
  CHF: "chf",
  CLP: "clp",
  CNY: "cny",
  COP: "cop",
  CRC: "crc",
  CVE: "cve",
  CZK: "czk",
  DKK: "dkk",
  DOP: "dop",
  DZD: "dzd",
  EGP: "egp",
  EUR: "eur2",
  FJD: "fjd",
  GBP: "gbp",
  GEL: "gel",
  GTQ: "gtq",
  GYD: "gyd",
  HKD: "hkd",
  HUF: "huf",
  IDR: "idr",
  ILS: "ils",
  INR: "inr",
  ISK: "isk",
  JPY: "jpy",
  KRW: "krw",
  KZT: "kzt",
  LKR: "lkr",
  MAD: "mad",
  MDL: "mdl",
  MXN: "mxn2",
  MYR: "myr",
  NAD: "nad",
  NGN: "ngn",
  NOK: "nok",
  NZD: "nzd",
  PAB: "pab",
  PEN: "pen",
  PHP: "php",
  PKR: "pkr",
  PLN: "pln",
  QAR: "qar",
  RON: "ron",
  RSD: "rsd",
  SAR: "sar",
  SCR: "scr",
  SEK: "sek",
  SGD: "sgd",
  SZL: "szl",
  THB: "thb",
  TRY: "try",
  TTD: "ttd",
  TWD: "twd",
  UAH: "uah",
  USD: "usd2",
  UYU: "uyu",
  VND: "vnd",
  XCD: "xcd",
  ZAR: "zar"
};
export const getFinalValue = (index, t, data) => {
  switch (index) {
    case 0:
      return (
        <div
          className={`animated__table-value ${
            data.timezone === "N/A" ? `animated_blurry_title` : ""
          }`}
        >
          {data.timezone === "N/A" ? t("TIMEZONE") : data.timezone}
        </div>
      );
    case 1:
      return t("WIRELESS");
    case 2:
      return (
        <div
          className={`animated__table-value ${
            data.country === "N/A" ? `animated_blurry_title` : ""
          }`}
        >
          {data.country === "N/A" ? (
            t("COUNTRY")
          ) : (
            <ReactCountryFlag
              countryCode={data.country}
              svg
              title={data.country}
              style={{
                width: "2em",
                height: "2em"
              }}
            />
          )}
        </div>
      );
    case 3:
      return (
        <div className={`animated__table-value animated_blurry_title`}>
          {data.city === "N/A" ? t("CITY") : data.city}
        </div>
      );
    case 4:
      return data?.name;
    case 5:
      return (
        <div className="animated__table-value animated_defined">
          <img src={checkIcon} alt="" />
          {t("DEFINED")}
        </div>
      );
    default:
      return "";
  }
};

export const formatDate = (date) => {
  return format(date, "MMMM dd, yyyy");
};
export const renderRTLClass = () => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  if (lang === "ac" || lang === "heb" || lang === "ar" || lang === "he") {
    return "theme_dir__rtl";
  } else return "";
};

export const languages = [
  {code: "cs", name: "Cesky", alt: "Cesky", flagCode: "cz"},
  {code: "de", name: "Deutsch", alt: "Deutsch", flagCode: "de"},
  {code: "en", name: "English", alt: "ENGLISH", flagCode: "gb"},
  {code: "es", name: "Español", alt: "Español", flagCode: "es"},
  {code: "el", name: "Ελληνικά", alt: "Ελληνικά", flagCode: "gr"},
  {code: "fr", name: "Français", alt: "FRENCH", flagCode: "fr"},
  {code: "hu", name: "Magyar", alt: "Magyar", flagCode: "hu"},
  {code: "fi", name: "Suomalainen", alt: "Suomalainen", flagCode: "fi"},
  {code: "is", name: "Islenskur", alt: "islenskur", flagCode: "is"},
  {code: "et", name: "Eesti keel", alt: "Eesti keel", flagCode: "ee"},
  {code: "hi", name: "हिंदी", alt: "Hindi", flagCode: "in"},
  {code: "yue", name: "粵語", alt: "Cantonese", flagCode: "cn"},
  {code: "th", name: "แบบไทย", alt: "Thai", flagCode: "th"},
  {code: "bn", name: "বাংলা", alt: "Bengali", flagCode: "bd"},
  {code: "ms", name: "Melayu", alt: "Malay", flagCode: "my"},
  {code: "ko", name: "한국인", alt: "한국인", flagCode: "kr"},
  {code: "hr", name: "Hrvatski", alt: "Hrvatski", flagCode: "hr"},
  {code: "zh-CN", name: "中国人", alt: "Chinese", flagCode: "cn"},
  {code: "ga", name: "Gaeilge", alt: "Gaeilge", flagCode: "ie"},
  {code: "id", name: "Bahasa Indonesia", alt: "Indonesian", flagCode: "id"},
  {code: "ja", name: "日本語", alt: "Japanese", flagCode: "jp"},
  {code: "sv", name: "Svenska", alt: "Svenska", flagCode: "se"},
  {code: "it", name: "Italiano", alt: "Italiano", flagCode: "it"},
  {code: "bg", name: "Български", alt: "Български", flagCode: "bg"},
  {code: "sr", name: "Српски", alt: "Serbian", flagCode: "rs"},
  {code: "uk", name: "Yкраїнська", alt: "Ukrainian", flagCode: "ua"},
  {code: "he", name: "עִברִית", alt: "Hebrew", flagCode: "il"},
  {code: "sk", name: "Slovenský", alt: "Slovak", flagCode: "sk"},
  {code: "da", name: "Dansk", alt: "Danish", flagCode: "dk"},
  {code: "ar", name: "عربي", alt: "Arabic", flagCode: "sa"},
  {code: "nl", name: "Nederlands", alt: "Nederlands", flagCode: "nl"},
  {code: "no", name: "Norsk", alt: "Norsk", flagCode: "no"},
  {code: "pl", name: "Polski", alt: "Polski", flagCode: "pl"},
  {code: "zh", name: "普通话", alt: "Mandarin", flagCode: "cn"},
  {code: "pt", name: "Português", alt: "Português", flagCode: "pt"},
  {code: "ro", name: "Română", alt: "Romanian", flagCode: "ro"},
  {code: "sl", name: "Slovenščina", alt: "Slovenian", flagCode: "si"},
  {code: "tr", name: "Türk", alt: "turkish", flagCode: "tr"},
  {
    code: "pt-br",
    name: "Português(BR)",
    alt: "Portuguese (BR)",
    flagCode: "br"
  }
];
export const getLang = (lang) => {
  switch (lang) {
    case "cz":
      return "cs";
    case "gr":
      return "el";
    case "kr":
      return "ko";

    default:
      return lang;
  }
};

export const calculateCursorPosition = (
  input,
  formattedPhoneNumber,
  cursorPosition,
  isDeleting
) => {
  let formattedIndex = 0;
  let inputIndex = 0;
  while (
    inputIndex < cursorPosition &&
    formattedIndex < formattedPhoneNumber.length
  ) {
    if (input[inputIndex] === formattedPhoneNumber[formattedIndex]) {
      inputIndex++;
    }
    formattedIndex++;
  }
  while (
    formattedIndex < formattedPhoneNumber.length &&
    formattedPhoneNumber[formattedIndex] === " "
  ) {
    if (formattedPhoneNumber[formattedIndex] === " " && isDeleting) {
      return formattedIndex;
    }
    formattedIndex++;
  }
  return formattedIndex;
};

export const handleSetPhoneNumber = (
  event,
  setPhoneNumber,
  setNumberValidate,
  setCountryCode,
  countryCodeinput,
  setDialCode,
  setFinalFormattedNumber,
  inputRef,
  mobileInputRef
) => {
  setNumberValidate("");
  let {value} = event.target;
  const input = event.target.value;
  const cursorPosition = event.target.selectionStart;
  const formatter = new AsYouType(countryCodeinput.toUpperCase());
  let formattedPhoneNumber;
  let parsedNumber;
  if (value.length > 5) {
    parsedNumber = parsePhoneNumberFromString(value, {
      defaultCountry: countryCodeinput.toUpperCase()
    });
  }

  if (parsedNumber && parsedNumber.country && value.length > 5) {
    setCountryCode(parsedNumber.country.toLowerCase());
  }

  // Detect if the user is deleting characters
  const isDeleting = event.nativeEvent.inputType === "deleteContentBackward";
  // Only format the phone number if the user is not deleting characters
  value = formatPhoneNumber(
    value,
    parsedNumber?.country || countryCodeinput.toUpperCase(),
    setDialCode,
    setFinalFormattedNumber
  );
  formattedPhoneNumber = formatter.input(value);
  setPhoneNumber(formattedPhoneNumber);
  requestAnimationFrame(() => {
    const newCursorPosition = calculateCursorPosition(
      input,
      formattedPhoneNumber,
      cursorPosition,
      isDeleting
    );
    if (inputRef?.current) {
      inputRef?.current?.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
    }
    if (mobileInputRef?.current) {
      mobileInputRef?.current.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
    }
  });

  // Set the formatted value to state
};

export const handleSetNumber = (
  value,
  country,
  setDialCode,
  setNumberValidate,
  setCountryCode,
  setPhoneNumber,
  setFinalFormattedNumber,
  phoneNumber,
  countryCodeinput
) => {
  setDialCode(country.dialCode);
  setNumberValidate("");
  setCountryCode(country.countryCode);
  // value = formatPhoneNumber(phoneNumber, country.countryCode.toUpperCase());
  value = formatPhoneNumber(
    String(phoneNumber),
    country?.countryCode?.toUpperCase() || countryCodeinput?.toUpperCase(),
    setDialCode,
    setFinalFormattedNumber
  );

  setPhoneNumber(value);
};
const formatPhoneNumber = (
  value,
  countryCode,
  setDialCode,
  setFinalFormattedNumber
) => {
  // Use AsYouType to format the number according to the country code
  value = String(value);
  const formatter = new AsYouType(countryCode);
  let adjustedValue = value;

  const formattedNumber = formatter.input(adjustedValue);
  // Get the country calling code
  const countryCallingCode = getCountryCallingCode(countryCode);

  setDialCode(countryCallingCode);
  const countryCallingCodeWithPlus = `+${countryCallingCode}`;

  // Ensure the formatted number does not start with the country calling code
  let finalFormattedNumber = formattedNumber;
  if (formattedNumber.startsWith(countryCallingCodeWithPlus)) {
    finalFormattedNumber = formattedNumber
      .replace(countryCallingCodeWithPlus, "")
      .trim();
  }
  finalFormattedNumber = finalFormattedNumber.replace(/[()]/g, "").trim();

  // Check if the number starts with brackets and the second character is zero
  if (
    finalFormattedNumber.startsWith("(") ||
    finalFormattedNumber.includes("-")
  ) {
    finalFormattedNumber = finalFormattedNumber.replace(/-/g, "").trim();
  }

  setFinalFormattedNumber(finalFormattedNumber);
  return finalFormattedNumber;
};

const getDeviceInfo = async () => {
  try {
    const parser = new UAParser();
    const result = parser.getResult();

    let deviceInfo = {
      "OS Name": result.os?.name || "N/A",
      "OS Version": result.os?.version || "N/A",
      "Browser Name": result.browser?.name || "N/A",
      "Browser Version": result.browser?.version || "N/A",
      "Device Type": result.device?.type || "N/A",
      "Device Name": "N/A",
      "Device Version": "N/A",
      "User Agent": result.ua || "N/A"
    };
    const highEntropyValues = navigator.userAgentData?.getHighEntropyValues([
      "model",
      "platformVersion"
    ]);
    if (highEntropyValues) {
      const {model, platformVersion} = await highEntropyValues.catch(
        () => ({})
      );
      deviceInfo["Device Name"] = model || "N/A";
      deviceInfo["Device Version"] = platformVersion || "N/A";
    }
    return deviceInfo;
  } catch (error) {
    console.log("error from getUserInfo", error);
    return null;
  }
};

export const logAPI = async (request, message, body, responseStatus = 200) => {
  if (!new_relic_license_key) return true;
  try {
    const nowInEST = Moment().tz("America/New_York");
    const unixTimestampEST = nowInEST.utc().unix();
    const payload = {
      timestamp: unixTimestampEST,
      message: message,
      logtype: "accesslogs",
      service: "subscription-service",
      hostname: "ubuntu-s-4vcpu-8gb-240gb-intel-sfo3-01",
      site: "location-tool",
      responseStatus: responseStatus,
      environment: "production",
      request: request,
      verb: "POST"
    };
    if (body) payload.body = body;
    const deviceInfo = await getDeviceInfo();
    if (deviceInfo) {
      payload.deviceInfo = deviceInfo;
    }
    if (navigator.userAgent) payload.userAgent = navigator.userAgent;
    let response = await axios.post(
      "https://log-api.newrelic.com/log/v1",
      payload,
      {
        headers: {
          "Api-Key": new_relic_license_key,
          "Content-Type": "application/json"
        }
      }
    );
    console.log(response.data);
  } catch (error) {
    console.log("new relic log api error", error);
  }
  return true;
};
